import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { getFunctions, httpsCallable } from  'firebase/functions';


const tenantCode = createSelector(
    state => state.core.tenantCode,
    code => code ?? '',
);

const navLinks = createSelector(
    state => state.core.navLinks,
    links => links,
);

const getNavLinks = createAsyncThunk(
    'core/getNavLinks',
    async (_,{ getState }) => {
        const tenantId = getState().auth.currentSelectedTenant;
        const functions = getFunctions();
        const getNavLinksFunction = httpsCallable(functions, 'tenants-getTenantNavLinks_v2');
        return await getNavLinksFunction(tenantId);
    },
);

const initialState = {
    tenantCode: '', 
    navLinks: [],
};

export const CoreSlice = createSlice({
    name: 'core',
    initialState: initialState,
    reducers: {
        setCode(state, action) {
            state.tenantCode = action.payload;
        },
        resetSlice() {
            return initialState;
        },
    },
    extraReducers: {
        [getNavLinks.fulfilled]: (state, action) => {
            state.navLinks = action.payload.data;
        },
        [getNavLinks.rejected]: (state, action) => {
            console.error(action.error);
        },
    },
});

export const coreActions = {
    ...CoreSlice.actions,
    getNavLinks,
};

export const coreSelectors = {
    tenantCode,
    navLinks,
};

export default CoreSlice.reducer;
