import { createSlice } from '@reduxjs/toolkit';
import { reducerUtils } from '../../../utils/reducerUtils';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getFunctions, httpsCallable } from  'firebase/functions';
import { createSelector } from '@reduxjs/toolkit';

// SELECTORS ---------------------------------------------------------

const allUsers = createSelector(
    state => state.users.list,
    users => users,
);

const invitesAndUsers = createSelector(
    allUsers,
    state => state.users.invites,
    (users, invites) => {
        const mappedUsers = users.map(user => ({ ...user, status: 'Confirmed' }));

        const mappedInvites = invites.map(invite => ({ ...invite, status: 'Invited' }));
        return [...mappedUsers, ...mappedInvites];
    },
);

const currentSelectedTenant = createSelector(
    state => state.auth.currentSelectedTenant,
    tenant => tenant,
);

const selectedUser = createSelector(
    allUsers,
    state => state.users.selectedUserId,
    (users, userId) => userId ? users.find(user => user.id === userId) : null,
);

const usersLoading = createSelector(
    state => state.users.isLoading,
    isLoading => isLoading,
);

const userSchema = createSelector(
    state => state.users.schema,
    schema => schema,
);

const roles = createSelector(
    state => state.users.roles,
    roles => roles,
);

const dealers = createSelector(
    state => state.users.dealers,
    dealers => dealers,
);

const facilities = createSelector(
    state => state.users.facilities,
    facilities => facilities,
);

const userAlert = createSelector(
    state => state.users.alert,
    alert => alert,
);


export const userSelectors = {
    allUsers,
    invitesAndUsers,
    currentSelectedTenant,
    selectedUser,
    usersLoading,
    userSchema,
    roles, 
    dealers, 
    facilities,
    userAlert,
};



/// ASYNC ACTIONS -------------------------------------------------

const inviteUser = createAsyncThunk(
    'users/inviteUser',
    async (newUser) => {
        const functions = getFunctions();
        const inviteUserFunction = httpsCallable(functions, 'users-inviteUser_v2');
        return await inviteUserFunction(newUser);
    },
);

const getUsers = createAsyncThunk(
    'users/getUsers',
    async (tenantId) => {
        const getUsersByTenantIdFunction = httpsCallable(getFunctions(), 'users-getUsersByTenantId_v2');
        return await getUsersByTenantIdFunction(tenantId);
    },
);

const getInvites = createAsyncThunk(
    'users/getInvites',
    async (tenantId) => {
        const getInvitesByTenantIdFunction = httpsCallable(getFunctions(), 'users-getInvitesByTenantId_v2');
        return await getInvitesByTenantIdFunction(tenantId);
    },
);


const updateUser = createAsyncThunk(
    'users/updateUser',
    async (updatedUser) => {
        const functions = getFunctions();
        const updateUserFunction = httpsCallable(functions, 'users-updateUser');
        return await updateUserFunction(updatedUser);
    },
);

const deleteUser = createAsyncThunk(
    'users/deleteUser',
    async (userId) => {
        const functions = getFunctions();
        const deleteUserFunction = httpsCallable(functions, 'users-deleteUser');
        return await deleteUserFunction(userId);
    },
);

const getRoles = createAsyncThunk(
    'users/getRoles',
    async (tenantId) => {
        const getRolesFunction = httpsCallable(getFunctions(), 'tenants-getTenantRoles');
        return await getRolesFunction(tenantId);
    },
);

const getDealers = createAsyncThunk(
    'users/getDealers',
    async (tenantId) => {
        const getDealersFunction = httpsCallable(getFunctions(), 'tenants-getTenantDealers');
        return await getDealersFunction(tenantId);
    },
);

const getFacilities = createAsyncThunk(
    'users/getFacilities',
    async (tenantId) => {
        const getFacilitiesFunction = httpsCallable(getFunctions(), 'tenants-getTenantFacilities');
        return await getFacilitiesFunction(tenantId);
    },
);

const { getUpdatedList, removeItemFromListById } = reducerUtils;



// REDUCER ---------------------------------------------------
const severityOptions = {
    error: 'error',
    info: 'info',
    success: 'success',
    warning: 'warning',
};

const setAlertError = (state, error) => state.alert = {
    open: true,
    message: error.message,
    severity: severityOptions.error,
};

const setAlertSuccess = (state, message) => state.alert = {
    open: true,
    message: message,
    severity: severityOptions.success,
};

const alertInitialState = {
    open: false,
    message: '',
    severity: severityOptions.success,
};

const initialState = {
    list: [],
    invites: [],
    schema: [],
    roles: [],
    dealers: [],
    facilities: [],
    selectedUserId: null,
    isLoading: false,
    alert: alertInitialState,
};

export const UserSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        selectUser(state, action) {
            state.selectedUserId = action.payload;
        },
        hideAlert(state) {
            state.alert.open = false;
        },
        resetSlice() {
            return initialState;
        },
    },
    extraReducers: {
        [inviteUser.fulfilled]: (state, action) => {
            state.invites = [...state.invites, action.payload.data];
            state.isLoading = false;
            setAlertSuccess(state, 'User invited!');
        },
        [inviteUser.pending]: (state) => {
            state.isLoading = true;
        },        
        [inviteUser.rejected]: (state, action) => {
            state.isLoading = false;
            setAlertError(state, action.error);
        },
        [deleteUser.fulfilled]: (state, action) => {
            state.list = removeItemFromListById(state.list, action.payload?.id);
            state.isLoading = false;
        },
        [deleteUser.pending]: (state) => {
            state.isLoading = true;
        },        
        [deleteUser.rejected]: (state) => {
            state.isLoading = false;
        },
        [getDealers.fulfilled]: (state, action) => {
            const { dealerGroups, dealerLocations } = action.payload.data;
            state.dealers = { dealerGroups, dealerLocations };
            state.isLoading = false;
        },
        [getDealers.pending]: (state) => {
            state.isLoading = true;
        },
        [getDealers.rejected]: (state) => {
            state.isLoading = false;
        },
        [getFacilities.fulfilled]: (state, action) => {
            const { facilityGroups, facilityLocations } = action.payload.data;
            state.facilities = { facilityGroups, facilityLocations };
            state.isLoading = false;
        },
        [getFacilities.pending]: (state) => {
            state.isLoading = true;
        },
        [getFacilities.rejected]: (state) => {
            state.isLoading = false;
        },
        [getRoles.fulfilled]: (state, action) => {
            state.roles = action.payload.data?.roles;
            state.isLoading = false;
        },
        [getRoles.pending]: (state) => {
            state.isLoading = true;
        },
        [getRoles.rejected]: (state) => {
            state.isLoading = false;
        },
        [getInvites.fulfilled]: (state, action) => {
            state.invites = action.payload.data;
            state.isLoading = false;
        },
        [getInvites.pending]: (state) => {
            state.isLoading = true;
        },        
        [getInvites.rejected]: (state) => {
            state.isLoading = false;
        },
        [getUsers.fulfilled]: (state, action) => {
            state.list = action.payload.data;
            state.isLoading = false;
        },
        [getUsers.pending]: (state) => {
            state.isLoading = true;
        },        
        [getUsers.rejected]: (state) => {
            state.isLoading = false;
        },
        [updateUser.fulfilled]: (state, action) => {
            state.list = getUpdatedList(state.list, action.payload);
            state.isLoading = false;
        },
        [updateUser.pending]: (state) => {
            state.isLoading = true;
        },        
        [updateUser.rejected]: (state) => {
            state.isLoading = false;
        },
    },
});

export const userActions = {
    ...UserSlice.actions,
    inviteUser,
    getUsers,
    getInvites, 
    updateUser,
    deleteUser,
    getRoles, 
    getDealers, 
    getFacilities,
};

export default UserSlice.reducer;
